/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";
import root from "react-shadow";
import { Field } from "formik";
import renderHTML from "react-render-html";

const renderField = (props, fieldProps): ReactElement => {
  const { field } = fieldProps;
  const { value = "" } = field;
  const { placeholder = "Click here to configure HTML markup…" } = props;

  const getHTML = (): string => {
    try {
      return renderHTML(value);
    } catch (e) {
      console.error(e);
      return placeholder;
    }
  };

  return (
    <>
      {value ? (
        <root.div className="quote">
          <>{getHTML()}</>
        </root.div>
      ) : (
        <p className="HtmlPreviewPlaceHolder">{placeholder}</p>
      )}
    </>
  );
};

const HtmlPreview = (props: {
  name: string;
  placeholder?: string;
}): ReactElement => {
  const { name } = props;
  return (
    <Field name={name}>{(fieldProps) => renderField(props, fieldProps)}</Field>
  );
};

export default HtmlPreview;
