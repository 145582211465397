import { ReactElement, useState, useRef } from "react";
import IntegryPopOver from "components/integry-design-system/atoms/integry-popover-v2";

import useOnClickOutside from "utils/use-onclick-outside";
import "./style.scss";

interface RenderControlProps {
  isMenuOpen: boolean;
}

interface MenuProps {
  closeMenu: () => void;
}
interface IProps {
  renderControl: (controlProps: RenderControlProps) => ReactElement;
  renderMenu: (menuProps: MenuProps) => ReactElement;
  onMenuOpen?: () => void;
  controlWrapperClass?: string;
  menuWrapperClass?: string;
}

const CreatableDropdown = ({
  renderControl,
  renderMenu,
  onMenuOpen,
  controlWrapperClass,
  menuWrapperClass,
}: IProps): ReactElement => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setShowDropdown(false);
  });
  const closeMenu = (): void => {
    setShowDropdown(false);
  };
  const controlProps = {
    isMenuOpen: showDropdown,
  };
  const menuProps = {
    closeMenu,
  };
  return (
    <div ref={ref} className="createable-dropdown-wrapper">
      <IntegryPopOver
        parentElement={ref?.current || undefined}
        close={() => setShowDropdown(false)}
        arrowPosition={{ top: 0 }}
        positions={["bottom"]}
        containerStyle={{
          paddingBottom: "5px",
        }}
        hideArrow
        align="end"
        show={showDropdown}
        popoverContent={
          <div className={`createable-dropdown-menu ${menuWrapperClass || ""}`}>
            {renderMenu(menuProps)}
          </div>
        }
      >
        <div
          className={`createable-dropdown ${controlWrapperClass || ""}`}
          onClick={(e) => {
            setShowDropdown(!showDropdown);
            if (!showDropdown && onMenuOpen) {
              onMenuOpen();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setShowDropdown(!showDropdown);
              if (!showDropdown && onMenuOpen) {
                onMenuOpen();
              }
            }
          }}
          tabIndex={-1}
          role="button"
        >
          {renderControl(controlProps)}
        </div>
      </IntegryPopOver>
    </div>
  );
};

export default CreatableDropdown;
