/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactElement, forwardRef, useRef, useState } from "react";
import TextField from "components/forms/v4/text-field";
import Textarea from "components/forms/v4/text-area";

import Checkbox from "components/forms/v3/checkbox";
import TextFieldTagged from "components/forms/v4/text-field-tagged";
import TextAreaTagged from "components/forms/v3/text-area-tagged";
import RadioGroup, { RadioOptions } from "components/forms/v4/radio-group-v2";
import TemplateStepSelector from "components/forms/v4/template-step-selector";

import HtmlPreview from "components/forms/v4/html-preview";
import TextPreview from "components/forms/v4/text-preview";
import Tag from "components/integry-design-system/atoms/tag";
import AceEditorTagged from "components/forms/v4/ace-editor-tagged/ace-editor-tagged";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import OverflowTooltip from "components/common/overflow-tooltip";
import CommonKebabMenu, {
  CommonKebabMenuItemProps,
} from "components/common/common-kebab-menu";

import { Step } from "features/templates/template-builder-v7/services/template-builder-interface";

import Select from "components/forms/v4/select-v2";
import kebabIcon from "images/vertical-kebab-menu.svg";
import blueGearIcon from "images/blue-gear-icon.svg";
import infoGreyIcon from "images/turbo.v2/info-grey.svg";

import "./styles.scss";

interface IProps {
  title?: string;
  description?: string;
  className?: string;
  name: string;
  id?: string;
  tag?: string;
  required?: boolean;
  kebabMenuPrefix?: string | ReactElement;
  menuItems?: CommonKebabMenuItemProps[];
  tooltip?: string;
  radioOptions?: RadioOptions[];
  showHeader?: boolean;
  templateSteps?: Step[];
  value?: string;
  fieldInfo?: string;
  truncateDescription?: boolean;
  lableClassName?: string;
  descriptionClassName?: string;
  docLink?: string;
  docLinkText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const TextFieldWithTitle = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: IProps, ref: any): ReactElement => {
    const {
      title,
      description,
      className = "",
      control,
      tag = "",
      required = false,
      showKebabMenu = false,
      kebabMenuPrefix = "Configure",
      menuItems,
      isActive,
      tooltip,
      showHeader = true,
      truncateDescription = false,
      fieldInfo = "",
      lableClassName = "",
      descriptionClassName = "",
      docLink = "",
      docLinkText = "Learn more.",
      ...rest
    } = props;
    const aceEditorRef = useRef(null);
    const [isHoverd, setIsHoverd] = useState(false);

    return (
      <div
        className={`form-field-v4 ${control} ${className}`}
        ref={ref}
        onMouseEnter={() => setIsHoverd(true)}
        onMouseLeave={() => setIsHoverd(false)}
      >
        {showHeader && (
          <div className="field-header">
            {(title || description) && (
              <div className="field-title-description">
                <div className="label-wrapper">
                  <label htmlFor={props.name} className={lableClassName}>
                    {title}
                    {required && <span className="required">*</span>}
                  </label>
                  {fieldInfo && (
                    <IntegryTooltip tooltipText={fieldInfo}>
                      <img
                        src={infoGreyIcon}
                        alt="gear-icon"
                        className="cursor-pointer"
                      />
                    </IntegryTooltip>
                  )}
                  {tag && <Tag className="ml-2" label={tag} type="grey" />}
                </div>
                {description && (
                  <div className="flex aling-center gap-5px">
                    <>
                      {truncateDescription ? (
                        <OverflowTooltip
                          className="field-description"
                          text={description}
                        />
                      ) : (
                        <p
                          className={`field-description ${descriptionClassName}`}
                        >
                          {description}
                        </p>
                      )}
                    </>
                    {docLink && (
                      <a
                        href={docLink}
                        target="_blank"
                        rel="noreferrer"
                        className="integry-link txt-sm"
                        style={{
                          marginTop: "2px",
                        }}
                      >
                        {" "}
                        {docLinkText}{" "}
                      </a>
                    )}
                  </div>
                )}
              </div>
            )}
            {showKebabMenu && menuItems && (
              <div className="field-kebab-menu">
                {isHoverd && (
                  <>
                    <div className="kebab-menu-prefix">
                      <img src={blueGearIcon} alt="gear-icon" />
                      {kebabMenuPrefix}
                    </div>
                    <CommonKebabMenu
                      menuItems={menuItems}
                      menuIcon={kebabIcon}
                      classname="left"
                    />
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {control === "input" && <TextField {...rest} title={props.title} />}
        {control === "textarea" && <Textarea {...rest} />}
        {control === "toggle" && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="toggle-wrapper"
          >
            <IntegryTooltip tooltipText={tooltip} disabled={!tooltip}>
              <label className="switch">
                <Checkbox {...props} />
                <span
                  className={`slider round ${props.disabled ? "disabled" : ""}`}
                />
              </label>
            </IntegryTooltip>
          </div>
        )}
        {control === "textfieldTagged" && <TextFieldTagged {...rest} />}
        {control === "textAreaTagged" && <TextAreaTagged {...rest} />}
        {control === "dropdown" && <Select {...rest} />}
        {control === "templateStepSelector" && (
          <TemplateStepSelector {...rest} />
        )}
        {control === "radioGroup" && (
          <RadioGroup
            {...rest}
            name={rest.name}
            id={rest.id || "radioGroup"}
            options={rest.radioOptions || []}
          />
        )}
        {control === "code" && (
          <AceEditorTagged {...rest} refProp={aceEditorRef} />
        )}
        {control === "HtmlPreview" && (
          <div className="html-preview-container integry-scrollbar-v2">
            <HtmlPreview {...rest} />
          </div>
        )}
        {control === "textPreview" && (
          <div className="html-preview-container text-preview-container integry-scrollbar-v2">
            <TextPreview {...rest} />
          </div>
        )}
      </div>
    );
  }
);
export default TextFieldWithTitle;
