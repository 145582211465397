import { combineReducers } from "redux";

import { integryApi } from "../../utils/integry-api";
import { accountsApi } from "../../utils/accounts-api";
import { userApi } from "utils/user/user-api";
import { flowApi } from "features/templates/api/useTemplateApi";
import { marketplaceFlowAPI } from "features/markteplace/v2/api/marketplace-flow-listing";
import { activityApi } from "features/templates/integration-builder/services/api/activity-listing-api";
import { sourceFlowListing } from "features/templates/integration-builder/services/api/source-flow-api";
import { actionsApi } from "components/turbo.v2/actions-container/services/actionsApi";
import { templatesApi } from "legacy-features/templates/v6/services/templates-api-rtk";
import { endUsersApi } from "features/end-users/services/end-users-apis";
import { setupAthAPI } from "components/integry-design-system/organisms/developer-setting/auth-setup-form/services/setup-auth-apis";
import { flowTestingApi } from "legacy-features/templates/v6/template-testing/services/flow-testing-api";
import { actionPayloadsApi } from "components/integry-design-system/organisms/sdk-previewer/apis";
import { codeStepApi } from "features/templates/api/code-step-api";
import { testTriggerAPI } from "features/templates/api/trigger-test-api";
import { runStepsAPI } from "features/integration-run-steps/api/useRunStepsApi";
import { useIntegrationsAPI } from "features/integrations/api/useIntegrations";
import { useIntegrationRunsAPI } from "features/integration-runs/api/useIntegrationRuns";
import { activityVersionsApi } from "features/templates/api/useActivityVersions";
import { useFlowname } from "features/templates/api/useFlowName";
import { useHttpCallApps } from "features/templates/api/useHttpCallApps";
import { useConnectedAccounts } from "features/templates/api/useConnectedAccounts";
import { usePollAPI } from "features/integrataion-polls/api/usePollApi";
import { useAvailableMarketplaceApps } from "features/markteplace/v3/api/useAvailableMarketplaceApps";
import { useAppsInMarketplace } from "features/markteplace/v3/api/useAppsInMarketplace";
import { useWorkspaceVariableAPI } from "features/account-settings/variables/api/useWorkspaceVariables";
import { useAppUserVariableAPI } from "features/account-settings/variables/api/useUserVariables";
import { useTemplateBrandingAppStep } from "features/templates/integration-builder/services/api/useTemplateBrandingAppSteps";
import { myApps, allApps, globallySelectedApp, globalUserId } from "./apps";
import { bundles } from "./bundles";
import { staticLists } from "./static-lists";
import { authTypes } from "./auth-types";
import { grantTypes } from "./grant-types";
import { authorizationTypesByApp } from "./authorization-types";
import { templatesByApp } from "./templates";
import { activitiesByApp } from "./activities";
import { endpointsByApp, endpointTypes } from "./endpoints";
import { setsByApp } from "./sets";
import { appCategories } from "./app-categories";
import { accountsByApp } from "./accounts";
import { genericObjects } from "./generic-objects";
import { retryCountByApp } from "./worker";
import { activityFields } from "./activity-fields";
import { refreshPreview } from "./app-preview";
import { appBuilder, appBuilderModels } from "./app-builder";
import { onboardingState } from "./onboarding-state";
import { toggle404Page } from "./toggle-404-page";

export const integryApp = combineReducers({
  myApps,
  allApps,
  globallySelectedApp,
  globalUserId,
  bundles,
  staticLists,
  authTypes,
  grantTypes,
  authorizationTypesByApp,
  templatesByApp,
  activitiesByApp,
  endpointsByApp,
  endpointTypes,
  setsByApp,
  appCategories,
  accountsByApp,
  genericObjects,
  retryCountByApp,
  activityFields,
  refreshPreview,
  appBuilder,
  appBuilderModels,
  onboardingState,
  toggle404Page,
  [integryApi.reducerPath]: integryApi.reducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
  [setupAthAPI.reducerPath]: setupAthAPI.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [flowApi.reducerPath]: flowApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  [sourceFlowListing.reducerPath]: sourceFlowListing.reducer,
  [flowTestingApi.reducerPath]: flowTestingApi.reducer,
  [templatesApi.reducerPath]: templatesApi.reducer,
  [endUsersApi.reducerPath]: endUsersApi.reducer,
  [actionPayloadsApi.reducerPath]: actionPayloadsApi.reducer,
  [actionsApi.reducerPath]: actionsApi.reducer,
  [codeStepApi.reducerPath]: codeStepApi.reducer,
  [testTriggerAPI.reducerPath]: testTriggerAPI.reducer,
  [runStepsAPI.reducerPath]: runStepsAPI.reducer,
  [useIntegrationsAPI.reducerPath]: useIntegrationsAPI.reducer,
  [marketplaceFlowAPI.reducerPath]: marketplaceFlowAPI.reducer,
  [useIntegrationRunsAPI.reducerPath]: useIntegrationRunsAPI.reducer,
  [activityVersionsApi.reducerPath]: activityVersionsApi.reducer,
  [useFlowname.reducerPath]: useFlowname.reducer,
  [useHttpCallApps.reducerPath]: useHttpCallApps.reducer,
  [useConnectedAccounts.reducerPath]: useConnectedAccounts.reducer,
  [usePollAPI.reducerPath]: usePollAPI.reducer,
  [useWorkspaceVariableAPI.reducerPath]: useWorkspaceVariableAPI.reducer,
  [useAppUserVariableAPI.reducerPath]: useAppUserVariableAPI.reducer,
  [useAppsInMarketplace.reducerPath]: useAppsInMarketplace.reducer,
  [useTemplateBrandingAppStep.reducerPath]: useTemplateBrandingAppStep.reducer,
  [useAvailableMarketplaceApps.reducerPath]:
    useAvailableMarketplaceApps.reducer,
});
