import { ReactElement, useEffect } from "react";
import { FeatureFlag } from "react-launch-darkly";
import { Route, Switch } from "react-router-dom";
import DashboardWrapper from "components/header-v3/dashboard-wrapper";
import { useFeatureFlags } from "context/featureFlagsContext";

import SideNavigation from "components/integry-design-system/atoms/side-navigation/side-navigation";

import urls from "utils/router-urls";
import { User } from "types/integry-user";

import companyProfileIcon from "images/account-setting/company-profile.svg";
import errorNotifcation from "images/account-setting/error-notifcation.svg";
import userPermission from "images/account-setting/user-permission.svg";
import teamManagement from "images/account-setting/team-management.svg";
import tagsManagement from "images/account-setting/tags.svg";
import tagsManagementSelected from "images/account-setting/tags-selected.svg";

import payloadsManagement from "images/account-setting/payloads.svg";
import payloadsManagementSelected from "images/account-setting/payloads-selected.svg";

import companyProfileSelectedIcon from "images/account-setting/company-profile-selected.svg";
import teamManagementSelected from "images/account-setting/team-management-selected.svg";
import userPermissionSelected from "images/account-setting/user-permission-selected.svg";
import errorNotifcationSelected from "images/account-setting/error-notifcation-selected.svg";
import embedSettingIcon from "images/embed-settings-icon.svg";
import embedSettingIconBlue from "images/embed-settings-icon-blue.svg";
import TeamManagement from "./team-management/team-management";
import ErrorNotifications from "./error-notifications/index";
import EndUserPermissions from "./end-user-permissions/end-user-permissions";
import "./account-setting.scss";
import CompanyProfile from "./company-profile/company-profile";
import EmbedSettings from "./embed";
import AppRequestSettings from "./app-requests";
import TagsManagement from "./tags-management";
import PayloadsManagement from "./payloads-management";
import { Variables } from "./variables";

interface SettingTab {
  name: string;
  icon: string;
  route: string;
  isSelected: boolean;
  activatedIcon: string;
}

interface SettingListProps {
  user: User;
  location: any;
  showPermissionsTab?: boolean;
}

const sideNavItems2 = (
  showPermissionsTab: boolean,
  variableFlags: boolean
): SettingTab[] => [
  {
    name: "Workspace Profile",
    icon: companyProfileIcon,
    route: urls.companyProfile,
    isSelected: true,
    activatedIcon: companyProfileSelectedIcon,
  },
  {
    name: "Team Management",
    route: urls.teamLink,
    isSelected: false,
    icon: teamManagement,
    activatedIcon: teamManagementSelected,
  },
  {
    name: "Notifications",
    icon: errorNotifcation,
    route: urls.errorNotifications,
    isSelected: false,
    activatedIcon: errorNotifcationSelected,
  },
  ...(showPermissionsTab
    ? [
        {
          name: "End-User Permissions",
          icon: userPermission,
          route: urls.developerSetting,
          isSelected: false,
          activatedIcon: userPermissionSelected,
        },
      ]
    : []),
  {
    name: "Security",
    icon: embedSettingIcon,
    route: urls.embedSdkSettings,
    isSelected: false,
    activatedIcon: embedSettingIconBlue,
  },
  /*   {
    name: "App Requests",
    icon: appRequestSettingIcon,
    route: urls.appRequestSettings,
    isSelected: false,
    activatedIcon: appRequestSettingIconBlue,
  }, */
  {
    name: "Tags",
    icon: tagsManagement,
    route: urls.tagsManagement,
    isSelected: false,
    activatedIcon: tagsManagementSelected,
  },
  {
    name: `Objects`,
    icon: payloadsManagement,
    route: urls.payloadsManagement,
    isSelected: false,
    activatedIcon: payloadsManagementSelected,
  },
  ...(variableFlags
    ? [
        {
          name: `Variables`,
          icon: payloadsManagement,
          route: urls.variables,
          isSelected: false,
          activatedIcon: payloadsManagementSelected,
        },
      ]
    : []),
];

const SettingList = (props: SettingListProps): ReactElement => {
  useEffect(() => {
    document.title = `Account Settings`;
  }, []);

  const { user, location, showPermissionsTab } = props;
  const variableFlags = useFeatureFlags({
    flag: "account-variables",
  });

  return (
    <DashboardWrapper user={user}>
      <div className="setting-page container">
        <div className="settings-wrapper">
          <div />
          <div />
          <div>
            <SideNavigation
              navItems={sideNavItems2(!!showPermissionsTab, variableFlags)}
              heading="Workspace Settings"
              currentUrl={location.pathname}
            />
          </div>
          <Switch>
            <Route
              path={urls.developerSetting}
              render={() => <EndUserPermissions />}
            />
            <Route
              path={urls.companyProfile}
              render={() => <CompanyProfile user={user} />}
            />
            <Route
              path={urls.userSetting}
              render={() => (
                <TeamManagement isAdminUser={!!user.is_admin_user} />
              )}
            />
            <Route
              path={urls.errorNotifications}
              render={() => <ErrorNotifications user={user} />}
            />
            <Route
              path={urls.embedSdkSettings}
              render={() => <EmbedSettings user={user} />}
            />
            <Route
              path={urls.appRequestSettings}
              render={() => <AppRequestSettings user={user} />}
            />
            <Route
              path={urls.tagsManagement}
              render={() => <TagsManagement user={user} />}
            />
            <Route
              path={urls.payloadsManagement}
              render={() => <PayloadsManagement user={user} />}
            />
            <Route path={urls.variables} render={() => <Variables />} />
          </Switch>
        </div>
      </div>
    </DashboardWrapper>
  );
};

const FlaggedSettingList = (props: SettingListProps): ReactElement => (
  <FeatureFlag
    flagKey="legacy-deployments-tab"
    renderFeatureCallback={() => <SettingList {...props} showPermissionsTab />}
    renderDefaultCallback={() => <SettingList {...props} />}
  />
);

export default FlaggedSettingList;
