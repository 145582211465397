import { configureStore } from "@reduxjs/toolkit";

import { templatesApi } from "legacy-features/templates/v6/services/templates-api-rtk";
import { flowApi } from "features/templates/api/useTemplateApi";
import { endUsersApi } from "features/end-users/services/end-users-apis";
import { setupAthAPI } from "components/integry-design-system/organisms/developer-setting/auth-setup-form/services/setup-auth-apis";
import { flowTestingApi } from "legacy-features/templates/v6/template-testing/services/flow-testing-api";
import { accountsApi } from "utils/accounts-api";
import { actionPayloadsApi } from "components/integry-design-system/organisms/sdk-previewer/apis";
import { userApi } from "utils/user/user-api";
import { integryApi } from "utils/integry-api";
import { useTemplateBrandingAppStep } from "features/templates/integration-builder/services/api/useTemplateBrandingAppSteps";

import { useAppsInMarketplace } from "features/markteplace/v3/api/useAppsInMarketplace";
import { actionsApi } from "components/turbo.v2/actions-container/services/actionsApi";
import { activityApi } from "features/templates/integration-builder/services/api/activity-listing-api";
import { sourceFlowListing } from "features/templates/integration-builder/services/api/source-flow-api";
import { codeStepApi } from "features/templates/api/code-step-api";
import { testTriggerAPI } from "features/templates/api/trigger-test-api";
import { runStepsAPI } from "features/integration-run-steps/api/useRunStepsApi";
import { useIntegrationsAPI } from "features/integrations/api/useIntegrations";
import { useIntegrationRunsAPI } from "features/integration-runs/api/useIntegrationRuns";
import { marketplaceFlowAPI } from "features/markteplace/v2/api/marketplace-flow-listing";
import { activityVersionsApi } from "features/templates/api/useActivityVersions";
import { useFlowname } from "features/templates/api/useFlowName";
import { useHttpCallApps } from "features/templates/api/useHttpCallApps";
import { useConnectedAccounts } from "features/templates/api/useConnectedAccounts";
import { usePollAPI } from "features/integrataion-polls/api/usePollApi";
import { useAvailableMarketplaceApps } from "features/markteplace/v3/api/useAvailableMarketplaceApps";
import { useWorkspaceVariableAPI } from "features/account-settings/variables/api/useWorkspaceVariables";
import { useAppUserVariableAPI } from "features/account-settings/variables/api/useUserVariables";

import { integryApp } from "store/reducers/integry-app";

const store = configureStore({
  reducer: integryApp,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
    })
      .concat(accountsApi.middleware)
      .concat(templatesApi.middleware)
      .concat(endUsersApi.middleware)
      .concat(setupAthAPI.middleware)
      .concat(flowTestingApi.middleware)
      .concat(userApi.middleware)
      .concat(actionsApi.middleware)
      .concat(actionPayloadsApi.middleware)
      .concat(activityApi.middleware)
      .concat(sourceFlowListing.middleware)
      .concat(codeStepApi.middleware)
      .concat(flowApi.middleware)
      .concat(runStepsAPI.middleware)
      .concat(testTriggerAPI.middleware)
      .concat(useIntegrationsAPI.middleware)
      .concat(useIntegrationRunsAPI.middleware)
      .concat(activityVersionsApi.middleware)
      .concat(marketplaceFlowAPI.middleware)
      .concat(useFlowname.middleware)
      .concat(useHttpCallApps.middleware)
      .concat(useConnectedAccounts.middleware)
      .concat(integryApi.middleware)
      .concat(useAvailableMarketplaceApps.middleware)
      .concat(useAppsInMarketplace.middleware)
      .concat(useWorkspaceVariableAPI.middleware)
      .concat(useAppUserVariableAPI.middleware)
      .concat(useTemplateBrandingAppStep.middleware)
      .concat(usePollAPI.middleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
