import { createApi } from "@reduxjs/toolkit/query/react";

import config from "utils/config";
import { integryBaseQuery } from "utils/http";
import {
  CreateAppUserVariableRequest,
  AppUserVariable,
  PaginatedAppUserVariables,
} from "../interface";

interface WorkspaceVariableQueryArgs {
  includeEmptyRow: boolean;
}

export const useAppUserVariableAPI = createApi({
  reducerPath: "appUserVariables",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["appUserVariables"],
  endpoints(build) {
    return {
      fetchAppUserVariable: build.query<
        PaginatedAppUserVariables,
        WorkspaceVariableQueryArgs | void
      >({
        query: () => ({
          url: "/api/v1/app-user-variables/?page_size=10000",
          method: "get",
        }),
        providesTags: ["appUserVariables"],
        transformResponse(
          response: PaginatedAppUserVariables,
          meta: unknown,
          args: WorkspaceVariableQueryArgs | void
        ) {
          const { includeEmptyRow } = args || { includeEmptyRow: false };
          if (!includeEmptyRow) {
            return response;
          }
          return {
            ...response,
            results: [
              ...response.results,
              {
                id: 0,
                key: "",
                test_value: "",
                is_required: false,
                is_system_generated: false,
                isNew: true,
              },
            ],
          };
        },
      }),
      createAppUserVariable: build.mutation<
        AppUserVariable,
        CreateAppUserVariableRequest
      >({
        query(data: CreateAppUserVariableRequest) {
          return {
            url: "/api/v1/app-user-variables/",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["appUserVariables"],
      }),
      updateAppUserVariable: build.mutation({
        query(data: AppUserVariable) {
          return {
            url: `/api/v1/app-user-variables/${data.id}`,
            method: "PATCH",
            data,
          };
        },
        invalidatesTags: ["appUserVariables"],
      }),

      deleteAppUserVariable: build.mutation({
        query({ id }: { id: number }) {
          return {
            url: `/api/v1/app-user-variables/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["appUserVariables"],
      }),
    };
  },
});

export const {
  useFetchAppUserVariableQuery,
  useCreateAppUserVariableMutation,
  useUpdateAppUserVariableMutation,
  useDeleteAppUserVariableMutation,
} = useAppUserVariableAPI;
