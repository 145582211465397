import { Component } from "react";
import { connect } from "react-redux";

import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { getStepIcon } from "features/templates/utils/steps-icon";
import OverflowTooltip from "../../common/overflow-tooltip";
import { getActivities } from "../../../selectors/activities";
import { stringifyCondition } from "utils/template-editor";
import config from "utils/config";

import expandIcon from "images/arrow-down.svg";
import dotLoader from "images/three-dot-loader.gif";
import arrowIcon from "images/templates-v3/arrow-top.svg";
import blueTick from "images/turbo.v2/blue-tick.svg";
import { ActivityType } from "enums/activity-types";
import { getCollapsedStepTitle } from "features/templates/utils/step-title";

class TagTreeStep extends Component {
  state = {
    expanded: true,
    searchString: "",
  };
  toggleStep = (e) => {
    e?.stopPropagation();
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  convertVariableToString = () => {
    const variables = (this.props.variables || [])
      .filter((variable) => !variable.isDeleted)
      .slice(0, 4);
    if (variables.length > 0) {
      const title = variables.reduce((acc, variable, index) => {
        return `${acc}${index === 0 ? "" : ","}  ${variable.name || `""`} = "${
          variable.value
        }"`;
      }, "");
      return this.props.variables.length > 4 ? `${title}...` : title;
    } else {
      return "";
    }
  };

  getStepTitle = () => {
    const {
      activity_type,
      condition_obj,
      fields: stepFields,
      activity: prop_activity,
      stepActivity,
      authorization_type,
      is_form_step,
      app: stepApp,
      comments,
    } = this.props;
    const activity = prop_activity ? prop_activity : stepActivity;
    const { step_condition_obj } = this.props;
    const { operand_1, operator } = step_condition_obj || {};
    const { app, name: activityTile } = activity || {};
    const { name, id: appId } = app || {};
    if (activity_type === ActivityType.DoWhile_Loop) {
      return "Do";
    }
    if (comments && appId !== config.storage.appId) {
      return comments;
    }
    if (is_form_step) {
      return stepApp?.name || "Form";
    }
    if (activity_type) {
      switch ((activity_type || "").toUpperCase()) {
        case "LOOP":
          return "Loop";
        case "SCHEDULE":
          return "Schedule";
        case "TRIGGER":
          if (activityTile && name) {
            let txt = (
              <span>
                {`${activityTile} `}
                {operand_1 && operator && (
                  <span>
                    <span style={{ fontWeight: 600 }}> IF </span>
                    <img
                      className="mr-1 ml-1"
                      style={{
                        width: "10px",
                        height: "10px",
                        objectFit: "contain",
                      }}
                      src={activity.app.icon_url}
                      alt="activity_app_icon"
                    />{" "}
                    {stringifyCondition(step_condition_obj)}
                  </span>
                )}
              </span>
            );
            return txt;
          } else return "";
        case "CONDITION_IF":
          return `If ${stringifyCondition(
            condition_obj && condition_obj.conditions
              ? condition_obj.conditions[0]
              : ""
          )}`;
        case "CONDITION_ELSEIF":
          return `Else If ${stringifyCondition(
            condition_obj && condition_obj.conditions
              ? condition_obj.conditions[0]
              : ""
          )}`;
        case "CONDITION_ELSE":
          return "Else";
        case "VARIABLE":
          return "Set Var " + this.convertVariableToString();
        case "MAKE_HTTP_CALL":
          return authorization_type?.app?.name || "HTTP Call";
        case "CODE":
          return "Custom Code";
        case "SETUP":
          return "Setup";
        case ActivityType.DoWhile_Loop:
          return "Do";
        case "ACTION":
          if (activity && appId === config.storage.appId) {
            return getCollapsedStepTitle(this.props);
          } else if (activity) {
            return activity && activityTile;
          }
          return "";
        default:
          if (activityTile && name) return `${activityTile}`;
          else return "";
      }
    } else {
      return "";
    }
  };

  getStepIcon = () => {
    const {
      activity: prop_activity,
      activity_type,
      authorization_type,
      stepActivity,
      customTagIcon,
      app: stepApp,
    } = this.props;
    const activity = prop_activity ? prop_activity : stepActivity;
    let defaultIcon = getStepIcon({
      activityType: activity_type,
      step: {
        ...this.props, // since props are passed as step in tag-tree-step component
      },
    });
    return customTagIcon || defaultIcon;
  };

  renderTag = (key, value, type) => {
    return (
      <div className="tag" key={key}>
        <div className="type">ABC</div>
        <div className="tile">{type}</div>
        <div className="tile">{key}</div>
        <div className="value">
          {typeof value === "string" ? value : JSON.stringify(value)}
        </div>
        <div>
          <button
            className={"btn add-tag-button"}
            type="button"
            onClick={() => this.createTag(key, type)}
          >
            Add Field
            <img src={arrowIcon} alt="Add Step" />
          </button>
        </div>
      </div>
    );
  };

  createTag = (key, type) => {
    const { insertTagFromDataTree, path, isTrigger } = this.props;
    let tag = "";
    if (isTrigger) {
      tag = `{trigger.${key}}`;
    } else {
      const tagPrefix = path.reduce((accumulator, currentValue) => {
        return accumulator + `steps.${currentValue}.`;
      }, "");
      tag = `{${tagPrefix}${type.toLowerCase()}.${key}}`;
    }
    insertTagFromDataTree(tag);
  };
  onSearch = (e) => {
    this.setState({ searchString: e.target.value });
  };

  render() {
    const {
      steps,
      depth,
      setSelectedTmplateStep,
      fields,
      path,
      is_visible,
      machine_name,
      comments,
      stepActivity,
      isFieldList,
      field_list,
      isCustom,
      customTags,
      doubleBracedTags,
      activity_id,
      is_form_step,
      isDisabled,
      hideIcon = false,
      expandOnSelect = false,
      title: stepTitle,
      description = "",
      selectedStepMachineName,
      isExpandableOnly,
    } = this.props;

    const disableStep =
      (isFieldList &&
        !(field_list && field_list.length && !field_list.isLoading)) ||
      isDisabled;
    const { expanded } = this.state;
    const title = is_form_step
      ? `${stepTitle} ${description ? `(${description})` : ""}`
      : this.getStepTitle();
    const icon = this.getStepIcon();
    const templateStep = isCustom
      ? {
          isCustom,
          customTags,
          comments,
          doubleBracedTags,
          machine_name,
          isExpandableOnly,
        }
      : {
          activity: stepActivity || {},
          fields: fields,
          path: path,
          is_visible: is_visible,
          machine_name: machine_name,
          activity_id: activity_id,
          app_id: this.props.app_id,
          title: title,
          is_form_step: this.props.is_form_step,
          test_output: this.props.test_output,
          includeOnly: this.props.includeOnly || "",
        };
    let nestedSteps = steps
      ? steps.filter(
          (step) =>
            (step.activity_id && step.activity_id !== "http_client") ||
            step.is_form_step
        )
      : [];

    const handleStepClick = (e) => {
      if (expandOnSelect) {
        this.toggleStep(e);
      } else if (!disableStep) {
        setSelectedTmplateStep(templateStep);
      }
    };

    return (
      <div
        className="tag-steps-container"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className={`${disableStep ? "tag-step-row" : "background-handler"} ${
            selectedStepMachineName && selectedStepMachineName === machine_name
              ? "selected"
              : ""
          }`}
          onClick={handleStepClick}
        >
          <div
            className={`tag-step-header${
              disableStep ? " disabled-tag-step" : ""
            }`}
            style={{
              marginLeft: depth * 28,
              opacity: disableStep ? 0.5 : 1,
              cursor: disableStep ? "not-allowed" : "pointer",
              minWidth: "200px",
            }}
          >
            {nestedSteps && nestedSteps.length ? (
              <img
                className={`expand-icon ${expanded ? "selected" : ""}`}
                src={expandIcon}
                alt="expand-icon"
                onClick={this.toggleStep}
              />
            ) : (
              ""
            )}
            {!hideIcon && (
              <div
                className="tag-step-icon align-center"
                onClick={handleStepClick}
              >
                <IntegryTooltip
                  tooltipText={stepActivity?.app?.name}
                  disabled={!stepActivity?.app?.name}
                >
                  <img src={icon} alt="step-icon" />
                </IntegryTooltip>
              </div>
            )}

            <OverflowTooltip
              className={"tag-step-title overflow-elipsis"}
              text={title}
              tooltipId={`tag-tooltip`}
              onClick={handleStepClick}
            />
            {selectedStepMachineName &&
              selectedStepMachineName === machine_name && (
                <img
                  src={blueTick}
                  alt="selected"
                  className="selected-step-icon"
                />
              )}
            {isFieldList && field_list && field_list.isLoading && (
              <div className="drop-icon">
                <img src={dotLoader} alt="step-loading-icon" />
              </div>
            )}
          </div>
        </div>

        {expanded && (
          <>
            {nestedSteps.map(
              (step, index) =>
                ((step.activity_id && step.activity_id !== "http_client") ||
                  step.is_form_step) && (
                  <TagTreeStepConnected
                    {...step}
                    depth={depth + 1}
                    setSelectedTmplateStep={setSelectedTmplateStep}
                    path={[...path, index]}
                    key={index}
                    stepActivity={step.activity}
                    isFieldList={isFieldList}
                    selectedStepMachineName={selectedStepMachineName}
                  />
                )
            )}
          </>
        )}
      </div>
    );
  }
}

const TagTreeStepConnected = connect((state, ownProps) => {
  const { activity_type, activity_id } = ownProps;
  const activities =
    getActivities(state.activitiesByApp, ownProps.app_id, activity_type)
      .items || [];
  return {
    activity: activities.find((activity) => activity.id === activity_id),
  };
})(TagTreeStep);

export default TagTreeStepConnected;
